import { useEffect, useState, forwardRef } from "react";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { resetToastMessage } from "../../../store/features/global/global";

const SnackBar = (props) => {
	const [open, setOpen] = useState(true);
	const [message, setMessage] = useState((props && props.message) || "");
	const [anchorOrigin] = useState(
		(props && props.anchorOrigin) || {
			vertical: "top",
			horizontal: "right",
		}
	);
	const [alertType] = useState((props && props.alertType) || "info");

	useEffect(async () => {
		if (message !== props.message) {
			await setOpen(false);
			setMessage(props.message);
			setOpen(true);
		}
	}, [props.message, message]);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		props.resetToastMessage();
		setOpen(false);
		setMessage("");
	};

	const MuiAlert = forwardRef(function MuiAlert(props, ref) {
		return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	return (
		<div>
			<Snackbar
				open={open}
				autoHideDuration={4000}
				onClose={handleClose}
				anchorOrigin={anchorOrigin}
			>
				<MuiAlert
					onClose={handleClose}
					severity={alertType}
					sx={{ width: "100%" }}
				>
					{message}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetToastMessage: () => dispatch(resetToastMessage()),
	};
};

export default connect(null, mapDispatchToProps)(SnackBar);
